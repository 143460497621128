body{
    font-family: 'Nunito Sans' !important;
    overflow-x: hidden;
}

.container{
    width: 100%;
    margin: 0 auto;
}

.beranda{
    display:flex;
    max-width: 1200px;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    padding: 150px 150px 150px;
    flex-direction: row;
    gap: 200px;
    .imageBackground {
        z-index: 0;
        width: 100%;
        height: 100%;
        max-width: 700px;
        max-height: 600px;
        position: absolute;
        right: 0px;
        top: 0;
    }
    .content{
        flex: 1;
        text-align: center;
        h1{
            font-size: 40px;
            color: black;
            text-align: left;
            .text1{
                font-weight: 900;
            }
            .text2{
                font-weight: 500;
            }
        }
        .tagLine{
            width: 50%;
            text-align: left;
            margin-top: -20px;
            margin-bottom: 40px;
            h3{
                color: #909090;
                font-size: 18px;
                font-weight: 500;
            }
        }
        .logoPublish{
            display: flex;
            flex-direction: row;
            gap: 30px;
            margin-bottom: 50px;
            img{
                width: 135px;
                height: 45px;
            }
        }
        .pageButton{
            display: flex;
            align-self: flex-start;
        }
    }
    .screenApp{
        z-index: 1;
        .imageIphone1 {
            width: 100%;
            max-width: 180px;
            position: absolute;
            right: 370px;
            top: 165px;
        }
        .imageIphone2 {
            width: 100%;
            max-width: 250px;
            position: absolute;
            right: 140px;
            top: 184px;
        }
        .imageSchedule {
            width: 100%;
            max-width: 150px;
            position: absolute;
            right: 450px;
            top: 430px;
        }
        .imageSummary {
            width: 100%;
            max-width: 150px;
            position: absolute;
            right: 55px;
            top: 160px;
        }
    }
}

.sectionTentang{
    background: #00264F;
    display: flex;
    // width: 100%;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 225px 50px 225px 50px;
    gap: 170px;
    align-items: center;
    .imageLogo{
        width: 100%;
        height: 100%;
        max-width: 350px; 
        max-height: 200px;
    }
    .content{
        width: 100%;
        max-width: 500px;
        display: flex;
        flex-direction: column;
        h2{
            font-size: 64;
            color: white;
            font-weight: 800;
        }
        h4{
            font-size: 20;
            color: #B6B6B6;
            font-weight: 400;
        }
    }
    .flag{
        position: absolute;
        right: 0px;
        margin-top: 500px;
    }
}

.sectionFitur{
    max-width: 1200px;
    margin: 0 auto;
    padding: 70px 70px 70px 70px;
    .headerText{
        text-align: center;
        h2{
            color: #202020;
            font-size: 40px;
            font-weight: 800;
        }
    }
    .subHeaderText{ 
        width: 100%;
        max-width: 900px; 
        margin: 0 auto;
        text-align: center;
        margin-bottom: 80px;
        h4{
            color: #6E6E6E;
            font-size: 20px;
            font-weight: 400;
        }
    }
    .featurepage{
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 20px 50px 20px 50px;
        gap: 100px;
        .feature{
            width: 100%;
            max-width: 400px;
            display: flex;
            flex-direction: column;
            gap: 15px;
            .imageFeature{
                align-self: center;
                img{
                    width: 230px;
                    height: 380px;
                }
            }
            .title{
                color: #B82222;
                font-size: 30px;
                font-weight: 700;
            }
            .textContent{
                color: #6E6E6E;
                font-size: 15px;
                font-weight: 400;
            }
        }
    }
}

.sectionBenefit{
    padding: 70px 70px 70px 70px;
}

.sectionFaq{
    padding: 70px 70px 70px 70px;
    background-color: #00264F;
}

.sectionHubungi{
    padding: 70px 70px 70px 70px;
}

.subSection{
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    row-gap: 50px;
    column-gap: 100px;
    flex: 1;
    align-items: center;
    margin-bottom: 60px;
    .contentImage{
        flex: 0.3;
        .imgIllustration1{
            width: 300px;
            height: 300px;
        }
        .imgIllustration2{
            width: 300px;
            height: 220px;
        }
    }
    .contentImageAbs{
        position: absolute;
        right: 0;
    }
    .contentImageAbs2{
        position: absolute;
        right: 0;
        margin-top: 70px;
        .imgIllustration3{
            width: 300px; 
            height: 450px; 
        }
        .imgIllustration4{
            width: 166px; 
            height: 358px; 
        }
        .imgIllustration5{
            width: 350px; 
            height: 358px; 
        }
    }
    .divider1{
        display: none;
    }
    .divider2{
        display: none;
    }
    .divider3{
        display: none;
    }
    .contentText{
        display: flex;
        flex-direction: column;
        flex: 0.7;
        .header{
            h2{
                color: #202020;
                font-size: 45px;
                font-weight: 800;
            }
            h3{
                color: #202020;
                font-size: 36px;
                font-weight: 800;
            }
        }
        .content{
            display: flex;
            flex-direction: column;
            h4{
                color: #6E6E6E;
                font-size: 18px;
                font-weight: 400;
            }
            h5{
                color: #6E6E6E;
                font-size: 14px;
                font-weight: 400;
            }
            .point{
                display: flex;
                flex-direction: row;
                align-items: center;
                column-gap: 30px;
                align-items: first baseline;
                h4{
                    color: #6E6E6E;
                    font-size: 18px;
                    font-weight: 400;
                }
            }
            .listQuestion{
                display: flex;
                flex-direction: column;
                gap: 20px;
                align-items: center;
                .question{
                    width: 100%;
                    max-width: 800px;
                    padding: 0px 20px 0px 20px;
                    background-color: white;
                    border-radius: 8px;
                    box-shadow: 0px 8px 12px -6px rgba(0, 0, 0, 0.03);
                    .questionTitle{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        cursor: pointer;
                        h4{
                            color: black;
                            font-size: 16px;
                            font-weight: 700;
                            flex: 1;
                            display: flex;
                            flex-direction: row;
                        }
                        img{
                            justify-content: flex-end;
                        }
                    }
                    .panel{
                        padding: 0px 20px 20px 0px;
                    }
                }
            }
        }
    }
}

.containerPrivacy{
    background-color: #00264F;
    .headerPrivacy{
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        gap: 70px;
        align-items: center;
        padding: 80px 100px 0px 200px;
        height: 250px;
        background-color: white;
        border-bottom-left-radius: 50px;
        border-bottom-right-radius: 50px;
        img{
            width: 100px;
            height: 200px; 
        }
        h2{
            color: #00264F;
            font-size: 36px;
            font-weight: 600;
        }
    }
    .contentPrivacy{
        max-width: 1200px;
        margin: 0 auto;
        padding: 50px 50px 50px 50px;
        p, ul, ol{
            font-size: 12px;
            color: white;
        }
        ul{
            margin-left: 30px;
        }
        .textBold{
            font-weight: 500;
            color: white;
        }
        .textItalic{
            font-style: italic;
        }
        h3{ 
            font-size: 18px;
            color: white;
            font-weight: 500;
        }
        table{
            width: 70%;
            ul{
                margin-left: 0px;
            }
            td{
                font-size: 12px;
                color: white;
                padding: 0;
                margin: 0;
                vertical-align: baseline;
            }
        }
    }
}

.navigation {
    background: #fff;
    border-bottom: solid 1px #eee;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    .navigation_inner {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        padding: 15px;
        display: flex;
        align-items: center;
        .brand {
            flex: 1;
        }
        .header_menu {
            margin-left: 500px;
            a{
                text-decoration: none;
                color: #333;
                font-size: 14px;
                margin-left: 20px;
                font-weight: 800;
                opacity: 0.7;
                transition: all 0.3s;
                &:hover {
                    color: #B82222;
                    transition: all 0.3s;
                }
            }
            button.buttonNavigation {
                background-color: #B82222 !important;
                border: solid 2px #B82222;
                padding: 7px 20px;
                border-radius: 8px;
                color: #FFF !important;
                opacity: unset;
                margin-left: 20px;
                cursor: pointer;
                &:hover {
                    background-color: #B82222;
                    color: #FFF !important;
                    transition: all 0.3s;
                    box-shadow: 0px 20px 40px #B82222;
                }
            }
        }
    }
}

.footer{
    background-color: #000000;
    padding: 70px 70px 70px 70px;
    .logo{
        width: 100%;
        max-width: 1200px;
        display: flex;
        flex: 1;
        margin: 40px auto 40px auto;
    }
    .content{
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        margin-bottom: 50px;
        justify-content: center;
        .logoPublish{
            display: flex;
            flex-direction: column;
            gap: 30px;
            margin-bottom: 50px;
            width: 100%;
            max-width: 350px;
            img{
                width: 135px;
                height: 45px;
            }
        }
        .menu{
            display: flex;
            flex-direction: column;
            gap: 15px;
            width: 100%;
            max-width: 250px;
        }
        .contactUs{
            display: flex;
            flex-direction: column;
            row-gap: 15px;
            width: 100%;
            max-width: 300px;
            .subContactUs{
                display: flex;
                flex: 1;
                flex-direction: row;
                column-gap: 15px;
                h5{
                    color: white;
                    font-size: 16px;
                    font-weight: 400;
                    margin-top: 0px;
                }
            }
        }
        a{
            text-decoration: none;
            color: white;
            font-size: 14px;
            margin-left: 20px;
            font-weight: 400;
            transition: all 0.3s;
            &:hover {
                color: white;
            }
        }
    }
    .copyright{
        hr{
            color: white;
            margin-bottom: 30px;
        }
        margin-bottom: 40px;
        h4{
            color: white;
            font-size: 16px;
            font-weight: 100;
            text-align: center;
        }
    }
}

.textBold{
    font-weight: 700;
    color: #202020;
}

button.primary{
    background: #B82222;
    color: #FFFFFF;
    text-decoration: none;
    padding: 10px 25px;
    display: inline-flex;
    font-size: 16px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
}

button.icon{
    background-color: #22B89C;
    color: #FFFFFF;
    padding: 10px 25px;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    border: none;
    gap: 15px;
    align-items: center;
    font-size: 16px;
    text-decoration: none;
}

.inputWithButton input{
    width: 100%;
    max-width: 200px;
    padding: 12px 20px;
    border: none;
    background-color: #F3F6F4;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.inputWithButton button{
    color: white;
    padding: 12px 20px;
    background-color: #B82222;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border: none;
}

.mobileMenu {
    display: none;
}


// for mobile device
@media only screen and (max-width: 480px){
    .container{
        width: 100%;
        margin: 0 auto;
    }
    .mobileMenu{
        display: block !important;
        padding: 14px;
        margin-right: 15px;
    }
    .header_menu{
        display: none;
        padding-top: 20px;
        padding-bottom: 30px;
        background: #fff;
        position: absolute;
        z-index: 1000;
        height: auto;
        width: 100%;
        right: 0;
        top: 85px;
        overflow: hidden;
        border-top: solid 1px #0000001a;
    }

    .header_menu.showMobileMenu{
        display: block !important;
    }

    .header_menu a {
        display: block;
        margin-bottom: 15px;
    }
    .beranda{
        display:flex;
        align-items: center;
        justify-content: center;
        padding: 120px 150px 250px;
        flex-direction: column;
        gap: 200px;
        .imageBackground {
            z-index: 0;
            width: 100%;
            height: 100%;
            max-width: 210px;
            max-height: 350px;
            position: absolute;
            right: 0;
            top: 0;
        }
        .content{
            flex: 0.5;
            text-align: center;
            h1{
                font-size: 30px;
                color: black;
                text-align: left;
                .text1{
                    font-weight: 900;
                }
                .text2{
                    font-weight: 500;
                }
            }
            .tagLine{
                width: 100%;
                text-align: left;
                margin-top: -20px;
                margin-bottom: 40px;
                h3{
                    color: #909090;
                    font-size: 16px;
                    font-weight: 500;
                }
            }
            .logoPublish{
                display: flex;
                flex-direction: row;
                gap: 30px;
                margin-bottom: 50px;
                img{
                    width: 135px;
                    height: 45px;
                }
            }
            .pageButton{
                display: flex;
                align-self: flex-start;
            }
        }
        .screenApp{
            .imageIphone1 {
                width: 100%;
                max-width: 150px;
                position: absolute;
                right: 180px;
                top: 520px;
            }
            .imageIphone2 {
                width: 100%;
                max-width: 200px;
                position: absolute;
                right: 30px;
                top: 547px;
            }
            .imageSchedule {
                width: 100%;
                max-width: 100px;
                position: absolute;
                right: 260px;
                top: 750px;
            }
            .imageSummary {
                width: 100%;
                max-width: 100px;
                position: absolute;
                right: 30px;
                top: 510px;
            }
        }
    }
    .sectionTentang{
        background: #00264F;
        display: flex;
        flex-direction: column;
        padding: 100px 70px 100px 70px;
        gap: 30px;
        align-items: center;
    }
    .sectionFitur{
    padding: 70px 70px 70px 70px;
        .headerText{
            text-align: center;
            h2{
                color: #202020;
                font-size: 35px;
                font-weight: 800;
            }
        }
        .subHeaderText{ 
            width: 100%;
            max-width: 900px; 
            margin: 0 auto;
            text-align: center;
            margin-bottom: 80px;
            h4{
                color: #6E6E6E;
                font-size: 20px;
                font-weight: 400;
            }
        }
        .featurepage{
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 20px 10px 20px 10px;
            gap: 100px;
            .feature{
                width: 100%;
                max-width: 400px;
                display: flex;
                flex-direction: column;
                gap: 30px;
                .imageFeature{
                    align-self: center;
                    img{
                        width: 230px;
                        height: 380px;
                    }
                }
                .title{
                    color: #B82222;
                    font-size: 25px;
                    font-weight: 700;
                    text-align: center;
                }
                .textContent{
                    color: #6E6E6E;
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }
    }
    .sectionBenefit{
        padding: 50px 30px 50px 30px;
    }
    
    .sectionFaq{
        padding: 50px 30px 50px 30px;
        background-color: #00264F;
    }
    
    .sectionHubungi{
        padding: 50px 30px 50px 30px;
    }
    
    .subSection{
        display: flex;
        flex-direction: column;
        row-gap: 50px;
        column-gap: 100px;
        flex: 1;
        align-items: center;
        margin-bottom: 60px;
        .contentImage{
            flex: 0.3;
            .imgIllustration1{
                width: 280px;
                height: 280px;
            }
            .imgIllustration2{
                width: 280px;
                height: 205px;
            }
        }
        .contentImageAbs{
            position: absolute;
            right: 0;
        }
        .divider1{
            display: block;
            margin-top: 300px;
        }
        .divider2{
            display: block;
            margin-top: 500px;
        }
        .divider3{
            display: block;
            margin-top: 250px;
        }
        .contentImageAbs2{
            position: absolute;
            right: 0;
            .imgIllustration3{
                width: 300px; 
                height: 450px; 
            }
            .imgIllustration4{
                width: 90px; 
                height: 200px; 
            }
            .imgIllustration5{
                width: 250px; 
                height: 200px; 
            }
        }
        .contentText{
            display: flex;
            flex-direction: column;
            .header{
                h2{
                    color: #202020;
                    font-size: 35px;
                    font-weight: 800;
                }
                h3{
                    color: #202020;
                    font-size: 30px;
                    font-weight: 800;
                }
            }
            .content{
                display: flex;
                flex-direction: column;
                h4{
                    color: #6E6E6E;
                    font-size: 18px;
                    font-weight: 400;
                }
                h5{
                    color: #6E6E6E;
                    font-size: 14px;
                    font-weight: 400;
                }
                .point{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    column-gap: 30px;
                    align-items: first baseline;
                    h4{
                        color: #6E6E6E;
                        font-size: 18px;
                        font-weight: 400;
                    }
                }
                .listQuestion{
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    .question{
                        width: 100%;
                        max-width: 300px;
                        padding: 0px 10px 0px 10px;
                        background-color: white;
                        border-radius: 8px;
                        box-shadow: 0px 8px 12px -6px rgba(0, 0, 0, 0.03);
                        .questionTitle{
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            h4{
                                color: black;
                                font-size: 12px;
                                font-weight: 700;
                                flex: 1;
                                display: flex;
                                flex-direction: row;
                            }
                            img{
                                justify-content: flex-end;
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }
        }
    }

    .containerPrivacy{
        background-color: #00264F;
        .headerPrivacy{
            display: flex;
            flex-direction: row;
            gap: 40px;
            align-items: center;
            padding: 90px 30px 0px 50px;
            height: 150px;
            background-color: white;
            border-bottom-left-radius: 50px;
            border-bottom-right-radius: 50px;
            img{
                width: 60px;
                height: 120px; 
            }
            h2{
                color: #00264F;
                font-size: 22px;
                font-weight: 600;
            }
        }
        .contentPrivacy{
            padding: 50px 50px 50px 50px;
            table{
                width: 100%;
                ul{
                    margin-left: 0px !important;
                }
                td{
                    font-size: 11px;
                    color: white;
                    padding: 0;
                    margin: 0;
                    vertical-align: baseline;
                }
            }
        }
    }

    .footer{
        background-color: #000000;
        padding: 70px 70px 70px 70px;
        .logo{
            display: flex;
            flex: 1;
        }
        .content{
            display: flex;
            flex-direction: column;
            column-gap: 10px;
            margin-bottom: 50px;
            .logoPublish{
                display: flex;
                flex-direction: column;
                gap: 30px;
                margin-bottom: 50px;
                width: 100%;
                max-width: 350px;
                img{
                    width: 135px;
                    height: 45px;
                }
            }
            .menu{
                display: flex;
                flex-direction: column;
                gap: 15px;
                width: 100%;
                max-width: 250px;
                margin-bottom: 50px;
            }
            .contactUs{
                display: flex;
                flex-direction: column;
                row-gap: 15px;
                width: 100%;
                max-width: 300px;
                .subContactUs{
                    display: flex;
                    flex: 1;
                    flex-direction: row;
                    column-gap: 15px;
                    h5{
                        color: white;
                        font-size: 12px;
                        font-weight: 400;
                        margin-top: 0px;
                    }
                }
            }
        }
        .copyright{
            hr{
                color: white;
                margin-bottom: 30px;
            }
            margin-bottom: 40px;
            h4{
                color: white;
                font-size: 11px;
                font-weight: 100;
                text-align: center;
            }
        }
    }

    .inputWithButton input{
        width: 100%;
        max-width: 175px;
        padding: 12px 20px;
    }
    
    .inputWithButton button{
        padding: 12px 20px;
    }
}

// for tablet device
@media only screen and (min-width: 481px) and (max-width: 850px){
    .container{
        width: 100%;
        // margin: 0 auto;
    }
    .mobileMenu {
        display: none;
    }
    .beranda{
        display:flex;
        align-items: center;
        justify-content: center;
        padding: 150px 70px 150px;
        flex-direction: row;
        gap: 100px;
        .imageBackground {
            z-index: 0;
            width: 100%;
            height: 100%;
            max-width: 400px;
            max-height: 500px;
            position: absolute;
            top: 0;
        }
        .content{
            flex: 0.5;
            text-align: center;
            h1{
                font-size: 30px;
                color: black;
                text-align: left;
                .text1{
                    font-weight: 900;
                }
                .text2{
                    font-weight: 500;
                }
            }
            .tagLine{
                width: 100%;
                text-align: left;
                margin-top: -20px;
                margin-bottom: 40px;
                h3{
                    color: #909090;
                    font-size: 16px;
                    font-weight: 500;
                }
            }
            .logoPublish{
                display: flex;
                flex-direction: row;
                gap: 30px;
                margin-bottom: 50px;
                img{
                    width: 135px;
                    height: 45px;
                }
            }
            .pageButton{
                display: flex;
                align-self: flex-start;
            }
        }
        .screenApp{
            flex: 0.5;
            z-index: 1;
            .imageIphone1 {
                width: 100%;
                max-width: 150px;
                position: absolute;
                right: 195px;
                top: 170px;
            }
            .imageIphone2 {
                width: 100%;
                max-width: 200px;
                position: absolute;
                right: 25px;
                top: 198px;
            }
            .imageSchedule {
                width: 100%;
                max-width: 100px;
                position: absolute;
                right: 280px;
                top: 400px;
            }
            .imageSummary {
                width: 100%;
                max-width: 100px;
                position: absolute;
                right: 35px;
                top: 150px;
            }
        }
    }
    .sectionTentang{
        background: #00264F;
        display: flex;
        flex-direction: row;
        padding: 225px 50px 225px 50px;
        gap: 55px;
        align-items: center;
    }
    .sectionFitur{
        padding: 70px 70px 70px 70px;
        .headerText{
            text-align: center;
            h2{
                color: #202020;
                font-size: 40px;
                font-weight: 800;
            }
        }
        .subHeaderText{ 
            width: 100%;
            max-width: 850px; 
            margin: 0 auto;
            text-align: center;
            margin-bottom: 80px;
            h4{
                color: #6E6E6E;
                font-size: 20px;
                font-weight: 400;
            }
        }
        .featurepage{
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding: 20px 50px 20px 50px;
            gap: 70px;
            .feature{
                width: 100%;
                max-width: 400px;
                display: flex;
                flex-direction: column;
                gap: 15px;
                .imageFeature{
                    align-self: center;
                    img{
                        width: 150px;
                        height: 250px;
                    }
                }
                .title{
                    color: #B82222;
                    font-size: 18px;
                    font-weight: 700;
                }
                .textContent{
                    color: #6E6E6E;
                    font-size: 12px;
                    font-weight: 400;
                }
            }
        }
    }
    
    .sectionBenefit{
        padding: 50px 70px 50px 70px;
    }
    
    .sectionFaq{
        padding: 70px 70px 70px 70px;
        background-color: #00264F;
    }
    
    .sectionHubungi{
        padding: 70px 70px 70px 70px;
    }
    .subSection{
        width: 100%;
        max-width: 850px;
        display: flex;
        flex-direction: row;
        row-gap: 20px;
        column-gap: 25px;
        flex: 1;
        align-items: center;
        margin-bottom: 60px;
        .contentImage{
            flex: 0.5;
            .imgIllustration1{
                width: 300px;
                height: 300px;
            }
            .imgIllustration2{
                width: 300px;
                height: 220px;
            }
        }
        .contentImageAbs{
            position: absolute;
            right: 0;
        }
        .contentImageAbs2{
            position: absolute;
            right: 0;
            margin-top: 70px;
            .imgIllustration3{
                width: 300px; 
                height: 450px; 
            }
            .imgIllustration4{
                width: 110px; 
                height: 225px; 
            }
            .imgIllustration5{
                width: 240px; 
                height: 225px; 
            }
        }
        .divider1{
            display: none;
        }
        .divider2{
            display: none;
        }
        .divider3{
            display: none;
        }
        .contentText{
            display: flex;
            flex-direction: column;
            flex: 0.57;
            .header{
                h2{
                    color: #202020;
                    font-size: 40px;
                    font-weight: 800;
                }
                h3{
                    color: #202020;
                    font-size: 30px;
                    font-weight: 800;
                }
            }
            .content{
                display: flex;
                flex-direction: column;
                h4{
                    color: #6E6E6E;
                    font-size: 18px;
                    font-weight: 400;
                }
                h5{
                    color: #6E6E6E;
                    font-size: 14px;
                    font-weight: 400;
                }
                .point{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    column-gap: 30px;
                    align-items: first baseline;
                    h4{
                        color: #6E6E6E;
                        font-size: 18px;
                        font-weight: 400;
                    }
                }
                .listQuestion{
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                    align-items: center;
                    .question{
                        width: 100%;
                        max-width: 850px;
                        padding: 0px 15px 0px 10px;
                        background-color: white;
                        border-radius: 8px;
                        box-shadow: 0px 8px 12px -6px rgba(0, 0, 0, 0.03);
                        .questionTitle{
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            h4{
                                color: black;
                                font-size: 13px;
                                font-weight: 700;
                                flex: 1;
                                display: flex;
                                flex-direction: row;
                            }
                            img{
                                justify-content: flex-end;
                            }
                        }
                    }
                }
            }
        }
    }

    .containerPrivacy{
        background-color: #00264F;
        .headerPrivacy{
            display: flex;
            flex-direction: row;
            gap: 60px;
            align-items: center;
            padding: 80px 30px 0px 100px;
            height: 200px;
            background-color: white;
            border-bottom-left-radius: 50px;
            border-bottom-right-radius: 50px;
            img{
                width: 70px;
                height: 140px; 
            }
            h2{
                color: #00264F;
                font-size: 32px;
                font-weight: 600;
            }
        }
        .contentPrivacy{
            padding: 50px 50px 50px 50px;
            table{
                width: 70%;
                ul{
                    margin-left: 0px;
                }
                td{
                    font-size: 12px;
                    color: white;
                    padding: 0;
                    margin: 0;
                    vertical-align: baseline;
                }
            }
        }
    }

    .navigation {
        background: #fff;
        border-bottom: solid 1px #eee;
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 1000;
        .navigation_inner {
            width: 100%;
            max-width: 850px;
            .brand {
                flex: 1;
            }
            .header_menu {
                margin-left: 160px;
                width: 100%;
                max-width: 850px;
                a{
                    text-decoration: none;
                    color: #333;
                    font-size: 14px;
                    margin-left: 20px;
                    font-weight: 800;
                    opacity: 0.7;
                    transition: all 0.3s;
                    &:hover {
                        color: #B82222;
                        transition: all 0.3s;
                    }
                }
                button.buttonNavigation {
                    background-color: #B82222 !important;
                    border: solid 2px #B82222;
                    padding: 7px 20px;
                    border-radius: 8px;
                    color: #FFF !important;
                    opacity: unset;
                    margin-left: 20px;
                    cursor: pointer;
                    &:hover {
                        background-color: #B82222;
                        color: #FFF !important;
                        transition: all 0.3s;
                        box-shadow: 0px 20px 40px #B82222;
                    }
                }
            }
        }
    }
    
    .footer{
        background-color: #000000;
        padding: 70px 70px 70px 70px;
        .logo{
            display: flex;
            flex: 1;
        }
        .content{
            display: flex;
            flex-direction: row;
            column-gap: 10px;
            margin-bottom: 50px;
            .logoPublish{
                display: flex;
                flex-direction: column;
                gap: 30px;
                margin-bottom: 50px;
                width: 100%;
                max-width: 200px;
                img{
                    width: 135px;
                    height: 45px;
                }
            }
            .menu{
                display: flex;
                flex-direction: column;
                width: 100%;
                max-width: 150px;
            }
            .contactUs{
                display: flex;
                flex-direction: column;
                row-gap: 5px;
                width: 100%;
                max-width: 200px;
                .subContactUs{
                    display: flex;
                    flex: 1;
                    flex-direction: row;
                    column-gap: 15px;
                    h5{
                        color: white;
                        font-size: 12px;
                        font-weight: 400;
                        margin-top: 0px;
                    }
                }
            }
            a{
                text-decoration: none;
                color: white;
                font-size: 14px;
                margin-left: 20px;
                font-weight: 400;
                transition: all 0.3s;
                &:hover {
                    color: white;
                }
            }
        }
        .copyright{
            hr{
                color: white;
                margin-bottom: 30px;
            }
            margin-bottom: 40px;
            h4{
                color: white;
                font-size: 16px;
                font-weight: 100;
                text-align: center;
            }
        }
    }

    .inputWithButton input{
        width: 100%;
        max-width: 175px;
        padding: 12px 20px;
    }
    
    .inputWithButton button{
        padding: 12px 20px;
    }
}
